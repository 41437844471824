import React, { createRef } from 'react';
import classNames from 'classnames';
import { ImageResizeOptions, WowImage } from 'wix-ui-tpa/cssVars';
import { RoleId } from '@wix/members-domain-ts';
import { CameraFill } from '@wix/wix-ui-icons-common/on-stage';

import {
  BadgeSize,
  BaseComponentProps,
  DataHook,
  Position,
  ProfileLayout,
} from '../../../../../types';
import {
  absorbArguments,
  forwardResizedImage,
} from '../../../../../services/event-handler';
import { maybeOpenContactsWindow } from '../../../../../services/navigation';
import MoreButton from '../../../../../common/components/MoreButton';
import Blocked from '../../../../../common/components/Blocked';
import RoleIcons from '../../../../../common/components/RoleIcons';
import Button from '../../../../../common/components/Button';
import FollowButton from '../../../../../common/components/FollowButton';
import LoaderOverlay from '../../../../../common/components/LoaderOverlay';
import styles from './MobileViewWidget.scss';
import PrivateMemberIndicator from '../../../../../common/components/PrivateMemberIndicator';
import { MEMBERS_AREA_PAGE_TITLE_ID } from '../../../../../constants/common';
import { acceptableImageExtensions } from '../../../../../constants/image';
import AvatarInput from '../../../../../common/components/AvatarInput';
import { BadgeList } from '../../../../../common/components/BadgeList';

const COVER_HEIGHT = 108;

export class MobileViewWidget extends React.Component<BaseComponentProps> {
  private coverInputRef = createRef<HTMLInputElement>();

  render = () => {
    const { profilePage } = this.props;
    const { showPrivateMemberIndicator } = this.props.computed;
    const { isCoverLoading, isSaving } = profilePage;

    return (
      <div data-hook={DataHook.MobileWidget}>
        <div className={classNames(styles.box, styles.desktopStylesOnMobile)}>
          {showPrivateMemberIndicator && (
            <PrivateMemberIndicator alignRight={true} />
          )}
          {this.renderCover()}
          {(isCoverLoading || isSaving) && this.renderLoader()}
          {this.renderMember()}
        </div>
      </div>
    );
  };

  private readonly shouldRenderFF = () => {
    const { site, globalSettings, computed } = this.props;
    const showFollowersMobile = globalSettings.showFollowersMobile ?? true;
    const { inCommunity, showAsBlocked, showAsBlockedByMember } = computed;
    const { isSocial } = site;

    return (
      isSocial &&
      showFollowersMobile &&
      inCommunity &&
      !showAsBlocked &&
      !showAsBlockedByMember
    );
  };

  private readonly shouldRenderMoreButton = () => {
    const { additionalActions, rolesActions } = this.props.computed;
    return rolesActions.length > 0 || additionalActions.length > 0;
  };

  private readonly handleRoleAction = (roleId: RoleId) => {
    if (roleId === RoleId.CONTACT_PAGE) {
      const { metaSiteId } = this.props;
      return maybeOpenContactsWindow(
        metaSiteId,
        this.props.member.uid,
        this.props.computed.isResponsiveEditor,
      );
    }

    this.props.handlers.executeRoleAction(roleId);
  };

  private readonly renderMoreButton = () => {
    const alignment = this.props.isRTL ? Position.Left : Position.Right;

    return (
      <div className={styles.moreButtonContainer}>
        <div>
          <MoreButton
            alignment={alignment}
            isRtlLocale={this.props.isRTL}
            isDisabled={this.props.profilePage.isProfilePreview}
            additionalActions={this.props.computed.additionalActions}
            rolesActions={this.props.computed.rolesActions}
            isMobile={true}
            onAdditionalAction={this.props.handlers.executeAdditionalAction}
            onRoleAction={this.handleRoleAction}
          />
        </div>
      </div>
    );
  };

  private readonly renderBadges = () => {
    const { site, computed, handlers, i18n } = this.props;
    const { isSocial } = site;
    const { badgesSettings } = computed;
    const { navigateToViewedMember } = handlers;

    return (
      badgesSettings.badges.length > 0 && (
        <div className={styles.badgesContainer}>
          <BadgeList
            {...badgesSettings}
            onClickMore={isSocial ? navigateToViewedMember : undefined}
            onBadgeClick={isSocial ? navigateToViewedMember : undefined}
            useTextRemainder={false}
            maxRows={1}
            align={isSocial ? 'left' : 'center'}
            size={BadgeSize.Small}
            i18n={i18n}
          />
        </div>
      )
    );
  };

  private readonly renderButtons = () => {
    const { profilePage, computed, site } = this.props;
    const {
      canEdit,
      inCommunity,
      isInProfilePage,
      showAsBlocked,
      showAsBlockedByMember,
      allowChat,
      showFollowButton,
      isDesignPreview,
      isPublicMemberCandidateNoOne,
    } = computed;

    const isDesignPreviewAndSocial = isDesignPreview && site.isSocial;
    const shouldShowChatButton =
      (inCommunity && !showAsBlocked && allowChat) || isDesignPreviewAndSocial;
    const shouldShowFollowButton =
      (inCommunity &&
        !showAsBlocked &&
        !showAsBlockedByMember &&
        showFollowButton) ||
      isDesignPreviewAndSocial;
    const shouldShowViewPublicProfileCTA =
      !inCommunity &&
      !showAsBlocked &&
      !showAsBlockedByMember &&
      isInProfilePage &&
      !isPublicMemberCandidateNoOne;

    if (canEdit && !profilePage.isProfilePreview && !isDesignPreview) {
      const { showEditProfileCTA, showViewPublicProfileCTA } = computed;

      return (
        <div className={styles.buttonContainer}>
          {inCommunity && showEditProfileCTA && this.renderEditButton()}
          {inCommunity &&
            showViewPublicProfileCTA &&
            this.renderViewPublicProfileButton()}
          {shouldShowViewPublicProfileCTA && this.renderJoinCommunityButton()}
          {this.shouldRenderMoreButton() && this.renderMoreButton()}
        </div>
      );
    }

    return (
      <div className={styles.buttonContainer}>
        {shouldShowChatButton && this.renderChatButton()}
        {shouldShowFollowButton && this.renderFollowUnfollowButton()}
        {this.shouldRenderMoreButton() && this.renderMoreButton()}
      </div>
    );
  };

  private readonly renderFollowers = () => {
    const { member, handlers, computed } = this.props;
    const { followersInstalled, profileWidgetTexts } = computed;
    const handleFollowersBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(true);
    });

    const handleFollowingBoxClick = absorbArguments(() => {
      handlers.navigateToViewedMemberFFPage(false);
    });

    return (
      <span className={styles.followingFollowers}>
        <button
          className={styles.followers}
          data-hook={DataHook.Followers}
          onClick={handleFollowersBoxClick}
          disabled={!followersInstalled}
        >
          {member.followerCount +
            ' ' +
            profileWidgetTexts.followersCountersText}
          {/* TODO: Add different translation key for 1 follower when translations are available */}
        </button>
        <div className={styles.divider} />
        <button
          className={styles.following}
          data-hook={DataHook.Following}
          onClick={handleFollowingBoxClick}
          disabled={!followersInstalled}
        >
          {member.followingCount +
            ' ' +
            profileWidgetTexts.followingCountersText}
        </button>
      </span>
    );
  };

  private readonly renderFollowUnfollowButton = () => {
    const { member, handlers, computed, profilePage } = this.props;
    const { profileWidgetTexts } = computed;

    return (
      <FollowButton
        dataHook={DataHook.FollowCTA}
        className={classNames(styles.button, styles.followButton)}
        followText={profileWidgetTexts.followButtonText}
        unfollowText={profileWidgetTexts.followingButtonText}
        isFollowing={member.isSubscribed}
        isDisabled={profilePage.isProfilePreview}
        type="text"
        onClick={absorbArguments(handlers.followOrUnfollow)}
      />
    );
  };

  private readonly renderChatButton = () => {
    const { profilePage, handlers, computed } = this.props;
    const { profileWidgetTexts } = computed;

    const handleChatButtonClick = absorbArguments(handlers.openChat);

    return (
      <Button
        className={styles.button}
        isDisabled={profilePage.isProfilePreview}
        onClick={handleChatButtonClick}
      >
        {profileWidgetTexts.messageButtonText}
      </Button>
    );
  };

  private readonly renderEditButton = () => (
    <button
      data-hook={DataHook.EditProfileCTA}
      className={styles.button}
      onClick={absorbArguments(this.props.handlers.toggleIsEditingProfile)}
    >
      {this.props.t<string>('profile-widget.edit')}
    </button>
  );

  private readonly renderViewPublicProfileButton = () => (
    <button
      data-hook={DataHook.ViewPubLicProfileCTA}
      className={styles.button}
      onClick={absorbArguments(this.props.handlers.togglePublicProfilePreview)}
    >
      {this.props.t<string>('profile-widget.view-public-profile')}
    </button>
  );

  private readonly renderJoinCommunityButton = () => {
    const { handlers } = this.props;
    const handleJoinCommunity = () => {
      handlers.executeRoleAction(RoleId.JOIN_COMMUNITY);
    };

    return (
      <button
        data-hook={DataHook.JoinCommunityCTA}
        className={classNames(styles.button, styles.communityJoinButton)}
        onClick={handleJoinCommunity}
      >
        {this.props.t<string>('MemberRoles.action_set.community')}
      </button>
    );
  };

  private readonly renderRoleIcons = () => {
    const { showRoleLabel } = this.props.computed;

    const roleIconsClasses = {
      [styles.roleIcons]: true,
      [styles.roleIconsWithTooltip]: true,
    };

    return showRoleLabel ? (
      <RoleIcons
        containerClassName={classNames(roleIconsClasses)}
        iconClassName={styles.roleIcon}
        roles={this.props.member.roles || []}
        rolesMap={this.props.rolesMap}
        t={this.props.t}
      />
    ) : null;
  };

  private readonly renderNameAndRoleIcons = () => (
    <div className={styles.nameAndRoleIcons}>
      <h1
        aria-describedby={MEMBERS_AREA_PAGE_TITLE_ID}
        data-hook={DataHook.MemberName}
        className={styles.profileName}
      >
        {this.props.member.name}
      </h1>
      {this.props.site.isSocial && this.renderRoleIcons()}
    </div>
  );

  private readonly renderTitle = () => {
    const { member, computed, t } = this.props;
    const { isDesignPreview } = computed;
    const { title } = member;
    const shouldShowTitlePreview = isDesignPreview && !title;
    return (
      <>
        {title && <p className={styles.title}>{title}</p>}
        {shouldShowTitlePreview && (
          <p className={styles.title}>
            {' '}
            {t<string>('design-tab.title-preview')}
          </p>
        )}
      </>
    );
  };

  private readonly renderMember = () => {
    const { showAsBlockedByMember } = this.props.computed;
    return showAsBlockedByMember ? (
      <div className={styles.member}>{this.renderAvatar()}</div>
    ) : (
      <div className={styles.member}>
        {this.renderAvatar()}
        {this.renderMemberDetails()}
      </div>
    );
  };

  private readonly renderMemberDetails = () => (
    <div className={styles.memberDetails}>
      {this.renderNameAndRoleIcons()}
      {this.shouldRenderFF() && this.renderFollowers()}
      {this.props.computed.showTitle && this.renderTitle()}
      {this.renderBadges()}
      {this.props.computed.showAsBlocked && (
        <Blocked className={styles.blocked} t={this.props.t} />
      )}
    </div>
  );

  private readonly renderAvatar = () => {
    const { member, site, computed, handlers, profilePage } = this.props;
    const { isSocial } = site;
    const { pictureStyle, canEdit } = computed;
    const { setMemberPicture } = handlers;

    return (
      <div className={styles.avatarButtonContainer}>
        <AvatarInput
          name={member.name}
          imageType={pictureStyle}
          url={member.picture}
          onClick={forwardResizedImage(setMemberPicture)}
          canUpload={!profilePage.isProfilePreview && canEdit}
          profileLayout={ProfileLayout.FullWidth}
          isMobile
        />

        {isSocial && this.renderButtons()}
      </div>
    );
  };

  private readonly renderLoader = () => {
    return (
      <LoaderOverlay
        dataHook={DataHook.Loader}
        className={styles.loader}
        size="small"
        backdropClassName={styles.loaderBackdrop}
      />
    );
  };

  private readonly renderCoverInputCTA = () => {
    const { computed, t, handlers } = this.props;
    const { showCoverPhoto } = computed;
    const { saveCoverPhoto } = handlers;

    return (
      showCoverPhoto && (
        <>
          <label htmlFor="coverPhotoInput" className={styles.uploadLabel} />
          <CameraFill
            size={20}
            className={styles.coverPhotoUploadIcon}
            aria-label={t('profile-widget.change-aria-label-photo')}
          />
          <input
            id="coverPhotoInput"
            ref={this.coverInputRef}
            type="file"
            accept={acceptableImageExtensions}
            className={styles.uploadInput}
            onChange={forwardResizedImage(saveCoverPhoto)}
            tabIndex={-1}
          />
        </>
      )
    );
  };

  private readonly renderCover = () => {
    const { profilePage, site, computed, t } = this.props;
    const { canEdit, mobileProfileCoverPhoto } = computed;

    const ariaLabel = mobileProfileCoverPhoto?.src
      ? t('profile-widget.change-aria-label-photo')
      : t('profile-widget.add-cover-photo.aria-label');

    return (
      <div
        data-hook={DataHook.CoverWrapper}
        className={classNames(styles.coverWrapper, styles.coverColor)}
      >
        {this.renderCoverImage()}
        {canEdit && site.isSocial && !profilePage.isProfilePreview && (
          <button
            className={classNames(styles.coverInputContainer, styles.canEdit)}
            aria-label={ariaLabel}
            onClick={() => this.coverInputRef.current?.click()}
          >
            {this.renderCoverInputCTA()}
          </button>
        )}
      </div>
    );
  };

  private readonly renderCoverImage = () => {
    const { computed } = this.props;
    const { mobileProfileCoverPhoto } = computed;

    return (
      <WowImage
        src={mobileProfileCoverPhoto?.src}
        className={styles.coverImage}
        data-hook={DataHook.HighQualityCover}
        resize={ImageResizeOptions.cover}
        sourceHeight={COVER_HEIGHT}
        sourceWidth={mobileProfileCoverPhoto?.imageProps.rw}
        height={COVER_HEIGHT}
        {...(mobileProfileCoverPhoto && {
          focalPoint: {
            x: mobileProfileCoverPhoto.position.x,
            y: mobileProfileCoverPhoto.position.y,
          },
        })}
        shouldUseLQIP
      />
    );
  };
}

export default MobileViewWidget;
